import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../redux/actions/users";

import { Link, withRouter } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout(params) {
    if (this.props.forceLogout().status === 200) {
      this.props.history.push("/");
    }
  }

  //   componentDidMount() {
  //     console.log("header mounted");
  //   }

  render() {
    return (
      <div className="p-5 flex justify-between text-white bg-gray-800">
        <div className="logo-wrapper select-none">Tokotype</div>
        <div className="nav-link">
          <ul className="flex justify-end gap-5">
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>

            {this.props.isLogin === false ? (
              <li>
                <Link to="/login">Login</Link>
              </li>
            ) : (
              <li>
                <Link to="#" onClick={this.onLogout}>
                  Logout
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
