import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import { login, checkLogin } from "../redux/actions/users";

// import Button from "@material-ui/core/Button";

// import "../styles/login.css";
// const links = [
//   { name: "Open roles", href: "#" },
//   { name: "Internship program", href: "#" },
//   { name: "Our values", href: "#" },
//   { name: "Meet our leadership", href: "#" },
// ];
// const stats = [
//   { name: "Offices worldwide", value: "12" },
//   { name: "Full-time colleagues", value: "300+" },
//   { name: "Hours per week", value: "40" },
//   { name: "Paid time off", value: "Unlimited" },
// ];

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // account_type:0,
      // mobileMenu:false,
      // logoIcon:'',
      email: "",
      password: "",
      from: "/dashboard/home",
      isLoading: false,
      err: "",
    };
    this.onLogin = this.onLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.onLogout = this.onLogout.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onLogin(event) {
    this.setState({ loaded: false, isLoading: true, err: "" });
    event.preventDefault();

    console.log("this.state.from");
    console.log(this.state.from);

    const { email, password } = this.state;
    this.props.login({ email, password }).then((res) => {
      console.log(res.data);

      if (res.code === 200) {
        this.setState({
          loaded: true,
          isLoading: false,
        });

        this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });

        // this.props.
        // alert("Login Success");
        // this.props.myBand().then((res)=>{
        //     this.props.history.push(this.state.from);
        // });
      } else {
        console.log(res);
        this.setState({ isLoading: false, err: res.message });
      }
    });
  }

  componentDidMount() {
    this.props.checkLogin().then((res) => {
      if (res.code === 200) {
        this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
      } else {
        this.setState({ isLogin: false });
      }
    });
  }

  // componentDidMount() {
  //   setTimeout(() => {
  //     this.setState({
  //       loaded: true,
  //     });
  //   }, 2500);
  // }

  render() {
    const { email, password } = this.state;

    let from = this.state.from;
    if (from === undefined) {
      from = "/dashboard/home";
    }
    // loaded, err, isLoading

    // if (isLoading) {
    //   return <div>Loading...</div>;
    // }
    // if (!loaded) {
    //   return <div>Loading...</div>;
    // }

    return (
      <div className="h-screen">
        {/* {this.props.isLogin !== true ? "" : <Redirect to="/dashboard"></Redirect>} */}
        {this.state.isLogin !== true ? "" : <Redirect to={from}></Redirect>}
        {/* min-h-screen mb-20 */}
        <div className="relative flex flex-col justify-center align-middle overflow-hidden bg-gray-50 py-6 sm:py-12 h-full">
          {/* min-h-full */}
          <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
                Tokotype
                {/* style={{ fontSize: 40, fontWeight: 700 }} */}
                {/* {this.props.isLogin.toString()} */}
                {/* {from} */}
              </div>
              {/* <img
                className="mx-auto h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              /> */}
              {/* <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2> */}
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={this.onLogin}
                // onSubmit={(e) => {
                //   e.preventDefault();
                //   console.log("LOGIN");
                // }}
              >
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      required
                      id="email"
                      name="email"
                      type="email"
                      // defaultValue={"admin@gmail.com"}
                      value={email}
                      onChange={this.handleChange}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Password
                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      // defaultValue={"admin"}
                      value={password}
                      onChange={this.handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>

              {/* <p className="mt-10 text-center text-sm text-gray-500">
                Not a member?{" "}
                <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  Start a 14 day free trial
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>

      //   <div className="login-container fullheight">
      //     {this.props.isLogin !== true ? "" : <Redirect to="/dashboard"></Redirect>}
      //     <div className="login-paper verticalalign">
      //       <h1>Login Brow!</h1>
      //       <form onSubmit={this.onLogin}>
      //         <span className="inputWrapper">
      //           <label htmlFor="email">Email: </label>
      //           <input
      //             autoComplete="email"
      //             required
      //             id="email"
      //             type="email"
      //             placeholder="email@email.com"
      //             value={email}
      //             onChange={this.handleChange}
      //           ></input>
      //         </span>
      //         <span className="inputWrapper">
      //           <label htmlFor="password">Password: </label>
      //           <input
      //             autoComplete="current-password"
      //             required
      //             id="password"
      //             type="password"
      //             placeholder="password"
      //             value={password}
      //             onChange={this.handleChange}
      //           ></input>
      //         </span>
      //         <span className="error-message">{err !== "" ? err : ""}</span>
      //         {isLoading === false ? (
      //           <span className="inputWrapper">
      //             <Button type="submit" className="login-btn" variant="contained" color="primary">
      //               Login
      //             </Button>
      //           </span>
      //         ) : (
      //           <span className="loading-message">Please Wait</span>
      //         )}
      //       </form>
      //     </div>
      //   </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (userData) => dispatch(login(userData)),
    checkLogin: (data) => dispatch(checkLogin(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
