import React, { Component } from "react";
import { connect } from "react-redux";

// import { forceLogout } from "../../../redux/actions/users";
// import { getAllProducts } from "../../../redux/actions/products";
import { getVouchers, deleteVoucher } from "../../../redux/actions/vouchers";

import { withRouter, Link } from "react-router-dom";
// import { formatMoney } from "../../../utils/format";

class Voucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      data: [],
      err: null,
    };

    this.onDelete = this.onDelete.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  loadData = () => {
    this.props.getVouchers().then((res) => {
      console.log(res.data);
      this.setState({ data: res.data, loaded: true });
    });
  };

  onDelete(params) {
    console.log("Delete");
    console.log(params.target.getAttribute("data-id"));
    var deleted = window.confirm("Are you sure you want to delete this voucher?");
    if (deleted) {
      this.props
        .deleteVoucher({ id: parseInt(params.target.getAttribute("data-id")) })
        .then((res) => {
          if (res.code === 200) {
            this.loadData();
            alert("Voucher deleted successfully!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  // toggleMenu(params) {}

  componentDidMount() {
    this.loadData();

    // console.log("header mounted");
    // this.props
    //   .getAllProducts()
    //   .then((res) => {
    //     console.log(res);
    //     this.setState({
    //       data: res.data,
    //       loaded: true,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  render() {
    const url = this.props.match.url;

    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Vouchers</span>
          <Link to={url + "/add"}>
            <div className="bg-blue-700 text-white cursor-pointer p-2 text-sm rounded-md select-none">Add Voucher</div>
          </Link>
          {/* <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          > 
            Add Vouchers
          </div>*/}
        </div>
        <div className="flex flex-col gap-2">
          {this.state.data?.map((item) => {
            return (
              <div key={item.id} className="flex flex-row bg-zinc-50 gap-2 cursor-pointer p-2 text-sm rounded-md shadow-md items-center align-middle">
                <div className="flex flex-col flex-1">
                  <span className="text-lg font-semibold">{item.code}</span>
                  <span className="text-md voucher-list-item">
                    {item.item.map((font) => {
                      return <span key={font.id}>{font.name}</span>;
                    })}
                  </span>
                  <div>
                    {item.licensee !== null ? <div className="redeem-badge redeem-true">Redeemed</div> : <div className="redeem-badge redeem-false">Available</div>}
                    {/* {JSON.stringify(item.licensee)} */}
                    {/* licensee */}
                  </div>
                </div>
                <div className="flex-0 flex flex-row gap-2 p-3">
                  <div
                    data-id={item.id}
                    onClick={this.onDelete}
                    className="text-sm p-2 bg-orange-700 text-white rounded cursor-pointer hover:bg-orange-900 min-w-16 text-center"
                  >
                    Delete
                  </div>
                  {/* <div className="text-sm p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center">Preview</div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getVouchers: (data) => dispatch(getVouchers(data)),
    deleteVoucher: (data) => dispatch(deleteVoucher(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Voucher));
