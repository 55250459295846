import React, { Component } from "react";
import { connect } from "react-redux";
import {
  //Link,
  withRouter,
} from "react-router-dom";

class FooterGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
      // account_type:0,
      // mobileMenu:false,
      // logoIcon:'',
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  render() {
    return (
      <div className="main-footer-general">
        <div className="col">
          <span className="footer-heading">Tokotype Foundry</span>
          <p style={{ display: "block", marginBottom: "2.5rem" }}>
            Tokotype is a type foundry & design store.
            <br />
            Office & Store:
            <br />
            Nonstop Building - Floor 3 No. 6<br />
            Jl. Tubagus Ismail No. 40
            <br />
            Sekeloa, Coblong
            <br />
            Kota Bandung, Jawa Barat, 40134
            <br />
          </p>
          <p>©2024 Tokotype Foundry LLC. All rights reserved.</p>
          <span className="collaborator-credits">Website by Collaborators</span>
        </div>
        <div className="col">
          <span className="footer-heading">Fonts</span>
          <ul>
            <li>
              <a href="http://www.tokotype.com">Retail Fonts</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Custom Fonts</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Goods</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Trial Fonts</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Home Page</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <span className="footer-heading">Resources</span>
          <ul>
            <li>
              <a href="http://www.tokotype.com">About Us</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">News & Articles</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Newsletter</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">In Use</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Contact</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <span className="footer-heading">Support</span>
          <ul>
            <li>
              <a href="http://www.tokotype.com">Fonts Licensing</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">FAQ</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Terms & Conditions</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Privacy Policies</a>
            </li>
            <li>
              <a href="http://www.tokotype.com">Customer Login</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //logout: userData => dispatch(logout(userData))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterGeneral));
