import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../redux/actions/users";

import { withRouter } from "react-router-dom";
// NavLink,

import image from "../assets/images/tokotype-logo.png";

class HeaderGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
    };
    // this.onLogout = this.onLogout.bind(this);
  }

  // onLogout(params) {
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  //   componentDidMount() {
  //     console.log("headerGeneral mounted");
  //   }

  render() {
    return (
      <div className="p-5 !pb-4 flex justify-center text-black bg-white border-b-black border-b-1 items-center" style={{ height: "80px" }}>
        <div className="logo-wrapper select-none text-center">
          <a href="https://redeem.tokotype.com" alt="Redeem Voucher Tokotype">
            <img src={image} alt="tokotype logo" width={"124px"} />
          </a>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderGeneral));
