import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// NavLink,
// Redirect

import { login } from "../redux/actions/users";
// checkLogin

import { checkVoucher } from "../redux/actions/vouchers";

class Termsandconditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      voucher: "",
      from: "/dashboard/home",
      isLoading: false,
      err: null,
    };
    // this.onSubmit = this.onSubmit.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  // handleChange(event) {
  //   this.setState({ [event.target.id]: event.target.value });
  // }

  // onSubmit(event) {
  //   this.setState({ loaded: false, isLoading: true, err: null });
  //   event.preventDefault();

  //   const { voucher } = this.state;
  //   this.props.checkVoucher({ voucher }).then((res) => {
  //     console.log(res);
  //     console.log(res.data);
  //     if (res.code !== 200) {
  //       this.setState({ isLoading: false, err: res.message });
  //     } else {
  //       // alert("Congratulation, Voucher is valid!");
  //       window.location.href = "./register-license?success=TRUE&voucher=" + btoa(voucher);
  //     }
  //   });

  //   // console.log("this.state.from");
  //   // console.log(this.state.from);

  //   // this.props.login({ email, password }).then((res) => {
  //   //   console.log(res.data);

  //   //   if (res.code === 200) {
  //   //     this.setState({
  //   //       loaded: true,
  //   //       isLoading: false,
  //   //     });
  //   //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
  //   //   } else {
  //   //     console.log(res);
  //   //     this.setState({ isLoading: false, err: res.message });
  //   //   }
  //   // });
  // }

  // componentDidMount() {
  // this.props.checkLogin().then((res) => {
  //   if (res.code === 200) {
  //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
  //   } else {
  //     this.setState({ isLogin: false });
  //   }
  // });
  // }
  // email

  render() {
    const { voucher } = this.state;

    return (
      <div className="h-screen">
        <div className="relative flex flex-col justify-center align-middle overflow-hidden bg-white h-full">
          {/* py-6 sm:py-12 */}
          <div className="py-5 text-center border-b-1 terms-and-conditions-heading">
            <span className="text-lg" style={{ fontSize: "28px", fontWeight: "500", fontFamily: "sinar-semibold", lineHeight: "100%" }}>
              Tokotype Font Gift Card Terms & Conditions
            </span>
          </div>
          <div className="terms-and-conditions-box mt-6" style={{ marginBottom: "96px" }}>
            <p>By redeeming a font through redeem.tokotype.com, you agree to the following terms and conditions:</p>
            <ol
              style={{
                listStyle: "upper-roman",
                paddingLeft: "30px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <li>
                <span className="heading-ol">Eligibility</span> Each redemption code is valid for one user only and is non-transferable. You must use redemption
                codes before their expiration date (if applicable).
              </li>
              <li>
                <span className="heading-ol">License Usage</span>
                Fonts redeemed through Tokotype are licensed for cross-media usage, <br />
                including: Desktop License: For use in design software on your local device. <br />
                Web License: For embedding on websites via webfont formats. Additional uses beyond these licenses may require separate licensing.
              </li>
              <li>
                <span className="heading-ol">Prohibited Actions</span>
                You may not share, resell, or redistribute the redeemed fonts or associated files. We strictly prohibit modifying or reverse-engineering the
                font files.
              </li>
              <li>
                <span className="heading-ol">Redemption Process</span>
                Visit redeem.tokotype.com. Enter your unique code. Provide accurate details during the redemption process. Download and use the fonts in
                compliance with the licensing terms.
              </li>
              <li>
                <span className="heading-ol">Support</span>
                If you encounter issues during the redemption process, contact us at{" "}
                <a href="mailto:mail@tokotype.com" alt="Tokotype Mail">
                  mail@tokotype.com
                </a>
                .
              </li>
              <li>
                <span className="heading-ol">Disclaimer</span>
                Tokotype Foundry reserves the right to revoke access to redeemed fonts if these terms are violated. Tokotype is not responsible for any loss or
                damage resulting from improper font usage.
              </li>
              <li>
                <span className="heading-ol">Governing Law</span>
                These terms and conditions are governed by the laws of the Republic of Indonesia.
              </li>
            </ol>
            <p>
              For more details, visit{" "}
              <a href="https://www.tokotype.com" alt="Tokotype Main Site">
                https://tokotype.com
              </a>{" "}
              or contact{" "}
              <a href="mailto:mail@tokotype.com" alt="Mail Tokotype">
                mail@tokotype.com
              </a>
              .
            </p>
          </div>
          {/* <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">Redeem Voucher</div>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">Terms and Conditions</div>
          </div> */}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (userData) => dispatch(login(userData)),
    checkVoucher: (userData) => dispatch(checkVoucher(userData)),
    // checkLogin: (data) => dispatch(checkLogin(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Termsandconditions));
