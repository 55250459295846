import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
// Redirect

import { login } from "../redux/actions/users";
// checkLogin

import { checkVoucher } from "../redux/actions/vouchers";

import ReCAPTCHA from "react-google-recaptcha";

import ilustrasi from "../assets/images/illustrasi-redeem.svg";

// import fs from "node:fs";
// import fs from "fs";
// import axios from "axios";
// import FormData from "form-data";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      voucher: "",
      from: "/dashboard/home",
      isLoading: false,
      err: null,
      image: null,
      captcha: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);

    // this.generateImage = this.generateImage.bind(this);
  }

  onCaptchaChange(e) {
    this.setState({ captcha: e, err: null });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.state.captcha !== null) {
      this.setState({ loaded: false, isLoading: true, err: null });

      const { voucher } = this.state;
      this.props.checkVoucher({ voucher }).then((res) => {
        // console.log(res);
        // console.log(res.data);
        if (res.code !== 200) {
          this.setState({ isLoading: false, err: res.message });
        } else {
          // alert("Congratulation, Voucher is valid!");
          window.location.href = "./register-license?sx=" + btoa("success=TRUE&voucher=" + voucher);
        }
      });

      // console.log("this.state.from");
      // console.log(this.state.from);

      // this.props.login({ email, password }).then((res) => {
      //   console.log(res.data);

      //   if (res.code === 200) {
      //     this.setState({
      //       loaded: true,
      //       isLoading: false,
      //     });
      //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
      //   } else {
      //     console.log(res);
      //     this.setState({ isLoading: false, err: res.message });
      //   }
      // });
    } else {
      this.setState({ err: "Please check Captcha" });
    }
  }

  // generateImage = async () => {
  //   const formData = {
  //     prompt: "Lighthouse on a cliff overlooking the ocean",
  //     output_format: "webp",
  //   };

  //   const response = await axios.postForm(`https://api.stability.ai/v2beta/stable-image/generate/core`, axios.toFormData(formData, new FormData()), {
  //     validateStatus: undefined,
  //     responseType: "arraybuffer",
  //     headers: {
  //       Authorization: `Bearer sk-MYAPIKEY`,
  //       Accept: "image/*",
  //     },
  //   });

  //   if (response.status === 200) {
  //     fs.writeFileSync("./lighthouse.webp", Buffer.from(response.data));
  //   } else {
  //     throw new Error(`${response.status}: ${response.data.toString()}`);
  //   }
  // };
  // componentDidMount() {
  //   this.generateImage();
  // }

  // componentDidMount() {
  // this.props.checkLogin().then((res) => {
  //   if (res.code === 200) {
  //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
  //   } else {
  //     this.setState({ isLogin: false });
  //   }
  // });
  // }
  // email

  // componentDidMount() {
  //   async function query(data) {
  //     const response = await fetch("https://api-inference.huggingface.co/models/runwayml/stable-diffusion-v1-5", {
  //       headers: { Authorization: "Bearer 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" },
  //       method: "POST",
  //       body: JSON.stringify(data),
  //     });
  //     const result = await response.blob();
  //     return result;
  //   }
  //   query({ inputs: "Astronaut riding a horse" }).then((response) => {
  //     console.log(response);
  //     var reader = new FileReader();
  //     reader.readAsDataURL(response);
  //     reader.onloadend = () => {
  //       var base64data = reader.result;
  //       console.log(base64data);
  //       this.setState({ image: base64data });
  //     };
  //     // Use image
  //   });
  // }

  render() {
    const { voucher } = this.state;

    return (
      <div className="h-screen">
        <div className="container-form">
          {/* className="relative flex flex-col justify-center align-middle overflow-hidden bg-white py-6 sm:py-12 h-full" */}
          <div className="form-card">
            {/* className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8" */}
            <div className="form-card-title-container">
              {/* className="sm:mx-auto sm:w-full sm:max-w-sm" */}
              <div className="form-card-title">Redeem your Fonts</div>
              <span className="form-card-subtitle">
                Enter the code on the back of your font gift card
                <br />
                that you’ve purchased offline.
              </span>

              <img src={ilustrasi} alt="Tokotype Redeem" />
              {/* className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900" */}
            </div>

            <div className="form-code-container">
              {/* className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm" */}
              <form className="space-y-5" action="#" method="POST" onSubmit={this.onSubmit}>
                <div>
                  <label htmlFor="voucher">
                    {/* className="block text-sm font-medium leading-6 text-gray-900 text-center" */}
                    Enter your code
                  </label>
                  {this.state.image !== null ? <img src={this.state.image} alt="result" /> : null}
                  <div className="mt-2">
                    <input
                      required
                      id="voucher"
                      name="voucher"
                      value={voucher}
                      placeholder="KJ1H34-YOUR-CODE-KJ1H23"
                      onChange={this.handleChange}
                      className={
                        "input-voucher block w-full uppercase border-1 border-black outline-slate-400 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6 "
                      }
                    />
                  </div>
                  <center>
                    <ReCAPTCHA sitekey="6Le6d40qAAAAAHZWviUuSdOsN07cHirECRjB6RGr" onChange={this.onCaptchaChange} />
                  </center>
                </div>
                {/* SITE KEY */}
                {/* 6Le6d40qAAAAAHZWviUuSdOsN07cHirECRjB6RGr */}
                {/* SECRET KEY */}
                {/* 6Le6d40qAAAAABWfBZ2xlZ5UdrCU1LfJIjjyjW6A */}
                {this.state.err !== null ? <div className="error-box text-red-500 text-center">{this.state.err}</div> : null}
                <div>
                  <button
                    type="submit"
                    className={"submit-voucher " + (this.state.captcha !== null ? "active" : "disabled")}
                    // flex w-full justify-center bg-black px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                    // py-1.5
                    // rounded-md
                  >
                    Continue
                  </button>
                </div>
              </form>
              <div className="flex justify-center gap-2 my-4">
                {/* <a className="href-link" href="https://www.tokotype.com?ref='tokotype_voucher'">
                  Homepage
                </a> */}
                <NavLink className="href-link" to="./terms-conditions">
                  Terms and Conditions Applied
                </NavLink>
                {/* <NavLink className="href-link" to="./privacy-policy">
                  Privacy Policy
                </NavLink> */}
              </div>
            </div>

            <div className="form-code-mobile">
              This page is only accessible on a desktop. <br />
              Please switch to a desktop device to continue.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (userData) => dispatch(login(userData)),
    checkVoucher: (userData) => dispatch(checkVoucher(userData)),
    // checkLogin: (data) => dispatch(checkLogin(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
