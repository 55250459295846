import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { createVoucher, getItems } from "../../../redux/actions/vouchers";

import Files from "react-files";

import { Switch, TextField, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// function not(a, b) {
//   return a.filter((value) => b.indexOf(value) === -1);
// }
// function intersection(a, b) {
//   return a.filter((value) => b.indexOf(value) !== -1);
// }
// function union(a, b) {
//   return [...a, ...not(b, a)];
// }

class VoucherAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      image: undefined,
      imageUrl: undefined,
      // items: [],
      checked: [],
      left: [],
      right: [],
      data: {
        prefix: undefined,
        suffix: undefined,
        code: undefined,
        name: undefined,
        font_name: undefined,
        license: undefined,
        package: undefined,
        description: undefined,
        style: undefined,
        is_active: true,
      },
      loading: false,
      err: null,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.not = this.not.bind(this);
    this.intersection = this.intersection.bind(this);
    this.union = this.union.bind(this);

    this.numberOfChecked = this.numberOfChecked.bind(this);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);
    this.handleCheckedRight = this.handleCheckedRight.bind(this);
    this.handleCheckedLeft = this.handleCheckedLeft.bind(this);

    this.customList = this.customList.bind(this);
  }

  numberOfChecked = (items) => this.intersection(this.state.checked, items).length;

  customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={this.handleToggleAll(items)}
            checked={this.numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 430,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItemButton key={value.id} role="listitem" onClick={this.handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={this.state.checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name}`} className="capitalize" />
              {/* List item  */}
              {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );
  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // setChecked(newChecked);
    this.setState({
      checked: newChecked,
    });
  };
  handleToggleAll = (items) => () => {
    if (this.numberOfChecked(items) === items.length) {
      this.setState({
        checked: this.not(this.state.checked, items),
      });
    } else {
      this.setState({
        checked: this.union(this.state.checked, items),
      });
    }
  };
  handleCheckedRight = () => {
    this.setState({
      right: this.state.right.concat(this.intersection(this.state.checked, this.state.left)),
    });
    this.setState({
      left: this.not(this.state.left, this.intersection(this.state.checked, this.state.left)),
    });
    this.setState({
      checked: this.not(this.state.checked, this.intersection(this.state.checked, this.state.left)),
    });
  };
  handleCheckedLeft = () => {
    this.setState({
      left: this.state.left.concat(this.intersection(this.state.checked, this.state.right)),
    });
    this.setState({
      right: this.not(this.state.right, this.intersection(this.state.checked, this.state.right)),
    });
    this.setState({
      checked: this.not(this.state.checked, this.intersection(this.state.checked, this.state.right)),
    });
  };

  not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  union(a, b) {
    return [...a, ...this.not(b, a)];
  }

  handleFileChange = (files) => {
    console.log("handleFileChange");
    console.log(files);

    this.setState({
      image: files[0],
      imageUrl: files[0].preview.url,
      data: {
        ...this.state.data,
        // code: files[0].name.replaceAll(" ", "-").toLowerCase().trim(),
      },
    });
  };
  handleFileError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };
  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.name === "code" ? e.target.value.replaceAll(" ", "-").toUpperCase().trim() : e.target.value,
      },
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    console.log(this.state.data);
    console.log(this.state.image);
    console.log(this.state.right);
    this.setState({
      err: null,
      loading: true,
    });

    this.props
      .createVoucher({
        // prefix: this.state.data.prefix,
        // suffix: this.state.data.suffix,
        code: this.state.data.code,
        name: this.state.data.name,
        font_name: this.state.data.font_name,
        package: this.state.data.package,
        license: this.state.data.license,
        items: this.state.right,
        // items: this.state.items,
        description: this.state.data.description,
        style: this.state.data.style,
        is_active: this.state.data.is_active,
        file: this.state.image !== null ? this.state.image : undefined,
      })
      .then((res) => {
        if (res.code === 200) {
          window.location.reload(false);
          alert("Create Success!");
        } else {
          alert("Something Error");
          console.log(res);
          this.setState({ err: res.message, loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        // alert("Something Error", err);
        this.setState({ err: "Something Error", loading: false });
      });
  }

  componentDidMount() {
    this.props.getItems().then((res) => {
      console.log(res);
      this.setState({
        left: res.data,
        // left: res.data.map((item) => item.id),
      });
    });
    // this.props
    //   .getNewsCategories()
    //   .then((res) => {
    //     console.log(res);
    //     this.setState({
    //       dataCategories: res.data,
    //       loaded: true,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Add Voucher</span>
        </div>
        <form className="w-full flex flex-col gap-3 pt-2" onSubmit={this.handleSubmit}>
          <div className="w-1/2">
            <Files
              required
              className="files-dropzone"
              onChange={this.handleFileChange}
              onError={this.handleFileError}
              accepts={["image/*"]}
              multiple={false}
              maxFileSize={10000000}
              minFileSize={0}
              clickable={true}
            >
              {this.state.image ? (
                <img
                  src={this.state.imageUrl ? this.state.imageUrl : ""}
                  alt={"thumbnail-" + this.state.data.name}
                  className="cursor-pointer news-item-thumbnail w-full hover:grayscale-0 "
                />
              ) : (
                ""
              )}
              <div
                className={
                  "cursor-pointer text-center block w-full p-2 text-sm border border-zinc-300 " +
                  (this.state.image === undefined ? "rounded flex align-middle justify-center items-center" : "")
                }
              >
                {this.state.image === undefined ? "Drop image here or click to upload" : this.state.image.name}
              </div>
            </Files>
          </div>

          <TextField required id="code" name="code" type="text" label="Code" value={this.state.data.code || ""} onChange={this.handleChange} />
          {/* <TextField id="prefix" name="prefix" type="text" label="Prefix" value={this.state.data.prefix || ""} onChange={this.handleChange} /> */}
          {/* <TextField id="suffix" name="suffix" type="text" label="Suffix" value={this.state.data.suffix || ""} onChange={this.handleChange} /> */}
          <TextField id="name" name="name" type="text" label="Name" value={this.state.data.name || ""} onChange={this.handleChange} />
          <TextField id="font_name" name="font_name" type="text" label="Font Name" value={this.state.data.font_name || ""} onChange={this.handleChange} />
          <TextField id="package" name="package" type="text" label="Package" value={this.state.data.package || ""} onChange={this.handleChange} />
          <TextField id="license" name="license" type="text" label="License" value={this.state.data.license || ""} onChange={this.handleChange} />
          <TextField id="style" name="style" type="text" label="Style" placeholder="2 Styles" value={this.state.data.style || ""} onChange={this.handleChange} />
          <TextField
            id="description"
            name="description"
            type="text"
            label="Description"
            multiline
            rows={2}
            value={this.state.data.description}
            onChange={this.handleChange}
          />

          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={5}>
              {this.customList("Font Family", this.state.left)}
            </Grid>
            <Grid item xs={2}>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={this.handleCheckedRight}
                  disabled={this.intersection(this.state.checked, this.state.left).length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={this.handleCheckedLeft}
                  disabled={this.intersection(this.state.checked, this.state.right).length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              {this.customList("Selected", this.state.right)}
            </Grid>
          </Grid>

          <FormControlLabel
            control={<Switch checked={this.state.data.is_active} />}
            label={<span style={{ fontSize: "14px" }}>Active</span>}
            name="is_active"
            onChange={() => {
              this.setState({
                data: {
                  ...this.state.data,
                  is_active: !this.state.data.is_active,
                },
              });
            }}
            className="w-fit"
          />
          {this.state.err !== null ? <div className="text-red-600">{this.state.err}</div> : ""}
          <div className="flex-0">
            <input
              type="submit"
              className="text-sm w-fit p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center"
              label="publish"
              value={this.state.loading ? "Loading..." : "Create Voucher"}
            />
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getItems: (data) => dispatch(getItems(data)),
    createVoucher: (data) => dispatch(createVoucher(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VoucherAdd));
