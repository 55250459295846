import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import queryString from "query-string";

import { checkVoucher, registerLicense } from "../redux/actions/vouchers";

import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";

// import { login } from "../redux/actions/users";
// checkLogin

class RegisterLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "/dashboard/home",
      isLoading: false,
      isAgree: false,
      voucher: "",
      items: [],
      item: null,
      data: {
        name: null,
        email: null,
        c_email: null,
        phone: null,
      },
      err: null,
    };

    this.loadData = this.loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    console.log(e.target.value);
    console.log(e.target.name);

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.name === "code" ? e.target.value.replaceAll(" ", "-").toUpperCase().trim() : e.target.value,
      },
    });
  }

  loadData() {
    try {
      const qr = queryString.parse(this.props.location.search);
      const parsed = queryString.parse(atob(qr.sx));
      console.log(JSON.parse(parsed.success.toLowerCase()));
      console.log(parsed.voucher);

      this.props.checkVoucher({ voucher: parsed.voucher }).then((res) => {
        console.log("Valid voucher");
        console.log(res);
        if (res.code === 200) {
          this.setState({
            voucher: res.data.code,
            items: res.data.item,
            item: res.data,
          });
        } else {
          // alert(res.message);
          this.setState({
            err: res.message,
          });
        }
      });
    } catch (e) {
      console.log(e);
      // window.location.href = "./";
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log("Submit");

    this.setState({
      err: null,
    });

    this.props
      .registerLicense({ ...this.state.data, code: this.state.voucher, isAgree: this.state.isAgree })
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          window.location.href =
            "./download-files?link=" + btoa("email=" + this.state.data.email + "&voucher=" + this.state.voucher + "&token=" + res.data.link_download);
          // this.setState({
          //   data: res.data,
          // });
          // window.alert("Congratulations! Your license has been registered successfully!");
        } else {
          this.setState({
            err: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          err: err.message,
        });
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { voucher, items, item } = this.state;

    return (
      <div className="h-screen">
        <div className="container-register">
          {/* relative flex w-full overflow-hidden bg-white py-1 px-1 sm:py-3 sm:px-3 h-full justify-center */}
          <div className="align-middle justify-center items-center self-center" style={{ width: "100%" }}>
            {voucher !== "" ? (
              <>
                <div className="card">
                  {/* <p>Detail Voucher</p>
                  <p>Detail Font: </p> */}
                  {/* <p className="voucher-code-text">{item.name}</p> */}
                  <p className="voucher-code-text">
                    <img src={item.imageurl} alt="Tokotype voucher" />
                  </p>
                  <span className="top-left">{voucher}</span>
                  <span className="top-right">{item.package}</span>
                  <span className="bottom-left">{item.style}</span>
                  <span className="bottom-right">{item.license}</span>
                  {/* {items.map((item, index) => {
                    return (
                      <div key={index}>
                        <p>{item.name}</p>
                      </div>
                    );
                  })} */}
                </div>
                <h3 className="my-3 form-card-title">Register License</h3>
                <form onSubmit={this.handleSubmit} className="w-full flex flex-col gap-3" action="#" method="POST">
                  <TextField className="input-licensee" required placeholder="Full Name" label="Licensee name" name="name" onChange={this.handleChange} />
                  <TextField
                    className="input-licensee"
                    required
                    type="email"
                    placeholder="email@user.com"
                    label="Email address"
                    name="email"
                    onChange={this.handleChange}
                  />
                  <TextField
                    className="input-licensee"
                    required
                    type="email"
                    placeholder="email@user.com"
                    label="Confirm email address"
                    name="c_email"
                    onChange={this.handleChange}
                  />
                  <TextField className="input-licensee" type="text" placeholder="Company" label="Company" name="company" onChange={this.handleChange} />
                  <TextField
                    className="input-licensee"
                    type="phone"
                    placeholder="+628123123456"
                    label="Phone (optional)"
                    name="phone"
                    onChange={this.handleChange}
                  />
                  <FormGroup className="justify-center items-center">
                    <FormControlLabel
                      required
                      control={<Checkbox />}
                      label="I agree with terms and conditions"
                      onChange={(e, f) => {
                        console.log(f);
                        this.setState({
                          isAgree: f,
                        });
                      }}
                    />
                  </FormGroup>
                  {this.state.err !== null ? <div className="text-red-600 text-base text-center my-3">{this.state.err}</div> : ""}
                  <input
                    type="submit"
                    variant="contained"
                    className="cursor-pointer noselect flex w-full justify-center rounded-md !bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    value="Download Fonts"
                  />
                </form>
              </>
            ) : (
              <div className="h-full">
                <div className="text-red-600 text-base text-center my-3">{this.state.err}</div>
                <NavLink to="./" className="mt-10 block text-center text-sm">
                  Back to Home
                </NavLink>
              </div>
            )}
          </div>
          {/* <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">Redeem Voucher</div>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">Register License</div>
          </div> */}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    checkVoucher: (userData) => dispatch(checkVoucher(userData)),
    registerLicense: (userData) => dispatch(registerLicense(userData)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterLicense));

// handleChange(event) {
//   this.setState({ [event.target.id]: event.target.value });
// }

// onSubmit(event) {
//   this.setState({ loaded: false, isLoading: true, err: null });
//   event.preventDefault();

//   const { voucher } = this.state;
//   this.props.checkVoucher({ voucher }).then((res) => {
//     console.log(res);
//     console.log(res.data);
//     if (res.code !== 200) {
//       this.setState({ isLoading: false, err: res.message });
//     } else {
//       // alert("Congratulation, Voucher is valid!");
//       window.location.href = "./register-license?success=TRUE&voucher=" + btoa(voucher);
//     }
//   });

//   // console.log("this.state.from");
//   // console.log(this.state.from);

//   // this.props.login({ email, password }).then((res) => {
//   //   console.log(res.data);

//   //   if (res.code === 200) {
//   //     this.setState({
//   //       loaded: true,
//   //       isLoading: false,
//   //     });
//   //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
//   //   } else {
//   //     console.log(res);
//   //     this.setState({ isLoading: false, err: res.message });
//   //   }
//   // });
// }

// componentDidMount() {
// this.props.checkLogin().then((res) => {
//   if (res.code === 200) {
//     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
//   } else {
//     this.setState({ isLogin: false });
//   }
// });
// }
// email
