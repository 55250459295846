import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { getItems, deleteItem } from "../../../redux/actions/vouchers";

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      data: [],
      err: null,
    };

    this.onPreview = this.onPreview.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  loadData = () => {
    this.props.getItems().then((res) => {
      console.log(res.data);
      this.setState({ data: res.data, loaded: true });
    });
  };

  onPreview(params) {
    console.log("Preview");
    console.log(params.target.getAttribute("data-id"));
  }

  onDelete(params) {
    console.log("Delete");
    console.log(params.target.getAttribute("data-id"));
    this.props
      .deleteItem({ id: parseInt(params.target.getAttribute("data-id")) })
      .then((res) => {
        if (res.code === 200) {
          this.loadData();
          alert("Item deleted successfully!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    console.log("header mounted");
    this.loadData();
  }

  render() {
    const url = this.props.match.url;

    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Items</span>
          <Link to={url + "/add"}>
            <div className="bg-blue-700 text-white cursor-pointer p-2 text-sm rounded-md select-none">Add Item</div>
          </Link>          
        </div>
        <div className="flex flex-col gap-2">
          {this.state.data?.map((item) => {
            return (
              <div key={item.id} className="flex flex-row bg-zinc-50 gap-2 cursor-pointer p-2 text-sm rounded-md shadow-md items-center align-middle">
                <div className="flex flex-col flex-1">
                  <span className="text-md font-semibold">{item.name}</span>
                  <span className="text-md font-light">{item.fileurl}</span>
                </div>
                <div className="flex-0 flex flex-row gap-2 p-3">
                  <div
                    data-id={item.id}
                    onClick={this.onDelete}
                    className="text-sm p-2 bg-orange-700 text-white rounded cursor-pointer hover:bg-orange-900 min-w-16 text-center"
                  >
                    Delete
                  </div>
                  {/* <div
                    data-id={item.id}
                    onClick={this.onPreview}
                    className="text-sm p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center"
                  >
                    Preview
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getItems: (data) => dispatch(getItems(data)),
    deleteItem: (data) => dispatch(deleteItem(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Items));
