import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
// Redirect

import { login } from "../redux/actions/users";
// checkLogin

import { checkVoucher } from "../redux/actions/vouchers";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      voucher: "",
      from: "/dashboard/home",
      isLoading: false,
      err: null,
    };
    // this.onSubmit = this.onSubmit.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  // handleChange(event) {
  //   this.setState({ [event.target.id]: event.target.value });
  // }

  // onSubmit(event) {
  //   this.setState({ loaded: false, isLoading: true, err: null });
  //   event.preventDefault();

  //   const { voucher } = this.state;
  //   this.props.checkVoucher({ voucher }).then((res) => {
  //     console.log(res);
  //     console.log(res.data);
  //     if (res.code !== 200) {
  //       this.setState({ isLoading: false, err: res.message });
  //     } else {
  //       // alert("Congratulation, Voucher is valid!");
  //       window.location.href = "./register-license?success=TRUE&voucher=" + btoa(voucher);
  //     }
  //   });

  //   // console.log("this.state.from");
  //   // console.log(this.state.from);

  //   // this.props.login({ email, password }).then((res) => {
  //   //   console.log(res.data);

  //   //   if (res.code === 200) {
  //   //     this.setState({
  //   //       loaded: true,
  //   //       isLoading: false,
  //   //     });
  //   //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
  //   //   } else {
  //   //     console.log(res);
  //   //     this.setState({ isLoading: false, err: res.message });
  //   //   }
  //   // });
  // }

  // componentDidMount() {
  // this.props.checkLogin().then((res) => {
  //   if (res.code === 200) {
  //     this.setState({ isLogin: true, from: this.props.location?.state?.from?.pathname });
  //   } else {
  //     this.setState({ isLogin: false });
  //   }
  // });
  // }
  // email

  render() {
    const { voucher } = this.state;

    return (
      <div className="h-screen">
        <div className="relative flex flex-col justify-center align-middle overflow-hidden bg-white py-6 sm:py-12 h-full">
          <div className="flex flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <div className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">Redeem Voucher</div>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" action="#" method="POST" onSubmit={this.onSubmit}>
                <div>
                  <label htmlFor="voucher" className="block text-sm font-medium leading-6 text-gray-900 text-center">
                    Voucher Code
                  </label>
                  <div className="mt-2">
                    <input
                      required
                      id="voucher"
                      name="voucher"
                      value={voucher}
                      placeholder="KJ1H34-YOUR-CODE-KJ1H23"
                      onChange={this.handleChange}
                      className="block w-full uppercase rounded-md border-1 border-zinc-300 outline-slate-400 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-center"
                    />
                  </div>
                </div>
                {this.state.err !== null ? <div className="text-red-500 text-center">{this.state.err}</div> : null}
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Redeem
                  </button>
                </div>
              </form>
              <div className="flex justify-center gap-2 my-4">
                <a className="href-link" href="https://www.tokotype.com?ref='tokotype_voucher'">
                  Homepage
                </a>
                <NavLink className="href-link" to="./terms-conditions">
                  Terms and Conditions
                </NavLink>
                <NavLink className="href-link" to="./privacy-policy">
                  Privacy Policy
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (userData) => dispatch(login(userData)),
    checkVoucher: (userData) => dispatch(checkVoucher(userData)),
    // checkLogin: (data) => dispatch(checkLogin(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));
