import React, { Component } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";

// import { forceLogout } from "../../../redux/actions/users";
// import { getAllProducts } from "../../../redux/actions/products";
import { getRedeems } from "../../../redux/actions/vouchers";

import { withRouter } from "react-router-dom";
// import { formatMoney } from "../../../utils/format";

class Redeem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      data: [],
      err: null,
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  // toggleMenu(params) {}

  componentDidMount() {
    console.log("header mounted");
    this.props
      .getRedeems()
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Redeem</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          >
            Add Redeem
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {this.state.data?.map((item) => {
            console.log(item);

            return (
              <div key={item.id} className="flex flex-row bg-zinc-50 gap-2 cursor-pointer p-2 text-sm rounded-md shadow-md items-center align-middle">
                {/* <div className="flex flex-0 p-2 items-center justify-center content-center">
                  {item?.images[0].url !== null ? (
                    <div className="avatar" style={{ backgroundImage: "url(" + item?.images[0].url + ")" }}></div>
                  ) : (
                    <div className="avatar">{item?.images[0].fullname[0].toUpperCase()}</div>
                  )}
                </div> */}
                <div className="flex flex-col flex-1">
                  <span className="text-lg font-bold">Code : {item?.voucher?.code}</span>
                  <span className="text-md font-light">Licensee Name : {item?.licensee_name}</span>
                  <span className="text-md font-semibold">Licensee Email : {item?.email}</span>
                  <span className="text-md font-light">Redeemed : {format(new Date(item?.created_at), "dd MMM yyy  |  p")}</span>
                  <span className="text-md font-bold">Items included : </span>
                  {item?.voucher?.item?.map((item) => {
                    return (
                      <span key={item.id} className="text-md font-light">
                        {item.name}
                      </span>
                    );
                  })}
                  <span>{item.description}</span>
                </div>
                <div className="flex-0 flex flex-row gap-2 p-3">
                  {/* <div className="text-sm p-2 bg-orange-700 text-white rounded cursor-pointer hover:bg-orange-900 min-w-16 text-center">Edit</div> */}
                  {/* <div className="text-sm p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center">Preview</div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // forceLogout: (data) => dispatch(forceLogout(data)),
    getRedeems: (data) => dispatch(getRedeems(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Redeem));
