import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { OutlinedInput, ThemeProvider, createTheme } from "@mui/material";

// const rootElement = document.getElementById('root')
// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   rootElement
// )

const theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          "&.Mui-focused": {
            color: "#000",
            backgroundColor: "#fff",
            outline: "none",
            border: "none",
            borderColor: "#198237",
          },
        },
        shrink: {
          //   transform: "translate(14px, -8px) scale(1) !important",
          transform: "translate(14px, -9px) scale(0.75) !important",
        },
        outlined: {
          transform: "translate(14px, 11px) scale(1)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: 10,
          paddingBottom: 10,
        },
        root: {
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          outline: "none",
          borderRadius: 0,
          "&.Mui-focused": {
            color: "#000",
            // backgroundColor: "#fff",
            outline: "none",
            border: "none",
            borderColor: "#f00",
            borderSize: 0,
          },
        },
        notchedOutline: {
          outline: "none",
          focused: {
            border: "none",
            outline: "none",
          },
          "&.Mui-focused": {
            color: "#f00",
            backgroundColor: "#fff",
            outline: "none",
            border: "none",
            notchedOutline: {
              borderColor: "#000",
            },
            OutlinedInput: {
              backgroundColor: "#f00",
            },
            "&.MuiOutlinedInput-notchedOutline": {
              root: {
                borderColor: "#f00",
              },
            },
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();

// npx tailwindcss -i ./src/input.css -o ./src/output.css --watch
